<template>
  <v-alert v-if="user && (event.referral_msg || (race && race.referral_msg))" dark color="#ddd" icon="" tile prominent class="event-referral">
    <h3>{{ $t('events.refer-a-friend') }}</h3>
    <p>{{(race ? race.referral_msg : null) || event.referral_msg}}</p>
    <v-btn class="white--text" :to="race ? {name: 'raceInvite', params: {id: this.event.id, raceId: this.race.id}} : {name: 'eventInvite', params: {id: this.event.id}}" :exact="true">
      <v-icon small class="mr-2">fa-bullhorn</v-icon>
      {{ $t('events.refer-a-friend') }}
    </v-btn>
  </v-alert>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventFriendReferral",
  props: {
    event: Object,
    race: Object,
  },
  data: function() {
    return {
    };
  },
  created() {
  },
  async mounted() {
    EventBus.$on('login-state-change', async user => {
    });
  },
  methods: {
    
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style scoped lang="scss">
  .event-header--logo + .event-referral { margin-top: -42px; padding-top: 42px; }
  .event-referral { color: black !important; background: linear-gradient(to top, #eee, #f4f4f4  ); border-bottom: solid 1px #ddd; }
  /*.event-referral i::before { color: black !important; }*/
</style>