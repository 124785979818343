<template>
  <div v-if="race && race.route && race.scoring === 'TRACK'">
    <v-card-text>
      <h3 class="mb-4">{{ $t('events.race.course-info') }}</h3>
      <p v-if="!leaderboardIsActive">{{ $t('events.race.course-info-unavailable', { from: $options.filters.localDate(race.from || event.from, "L"), till: $options.filters.localDate(race.till || event.till, "L") }) }}</p>
    </v-card-text>

    <v-card-text v-if="leaderboardIsActive">
      <v-dialog v-if="race.route_desc" v-model="showRouteDescriptionDialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn :color="event.color || 'primary'" v-on="on"><v-icon small class="mr-2">fa fa-map-signs</v-icon> {{ $t('events.race.course-map-description-short') }}</v-btn>
        </template>
        <v-card>
          <v-card-title>{{ $t('events.race.course-map-description') }}</v-card-title>
          <v-alert v-if="!isConnected" type="info" tile>
            {{ $t('events.race.course-map-description-locked') }}
          </v-alert>
          <v-card-text v-if="isConnected">
            <vue-markdown class="markdown" :html="false">{{race.route_desc }}</vue-markdown>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" text @click="showRouteDescriptionDialog = false">{{$t('shared.close')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>          
      <v-dialog v-model="showRouteGpxDialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn :color="event.color || 'primary'" outlined v-on="on" class="ms-4"><v-icon small class="mr-2">fa fa-download</v-icon> {{ $t('shared.download') }}</v-btn>
        </template> 
        <v-card>
          <v-card-title>{{ $t('events.race.course-gpx-download') }}</v-card-title>
          <v-card-text>
            {{ $t('events.race.course-gpx-download-msg') }}
          </v-card-text>
          <v-alert v-if="!isConnected" type="info" tile>
            {{ $t('events.race.course-gpx-download-locked') }}
          </v-alert>
          <v-card-text>
            <a href="https://corporatefitness.helpscoutdocs.com/article/125-upload-a-gpx-course-map-to-your-app-or-device" target="_blank">{{ $t('events.race.course-gpx-download-help') }}</a>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="isConnected" color="accent" @click="downloadGpx">{{ $t('shared.download') }}</v-btn>
            <v-btn color="accent" text @click="showRouteGpxDialog = false">{{$t('shared.close')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>          
      <v-dialog v-model="showTrackDialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn :color="event.color || 'primary'" outlined v-on="on" class="ms-4"><v-icon small class="mr-2">fa fa-route</v-icon> {{ $t('events.race.course-track-short') }}</v-btn>
        </template> 
        <v-card>
          <v-card-title>{{ $t('events.race.course-track') }}</v-card-title>
          <v-card-text>
            {{ $t('events.race.course-track-msg') }}
          </v-card-text>
          <v-alert v-if="!isConnected" type="info" tile>
            {{ $t('events.race.course-track-locked') }}
          </v-alert> 
          <v-card-text v-if="event.deeplink_url">
            <h3>{{ $t('events.race.course-track-app') }}</h3>
            {{ $t('events.race.course-track-app-msg') }}
            <p>
              <v-btn text :color="event.color || 'primary'" @click="showTrackDialog = false;showQrCode=true;"><v-icon small class="me-2">fa fa-mobile</v-icon> {{ $t('events.race.course-track-app-button') }}</v-btn>
            </p>
          </v-card-text>
          <v-card-text>
            <h3>{{ $t('events.race.course-track-device') }}</h3>
            {{ $t('events.race.course-track-device-msg') }}
            <p>
              <v-btn text :color="event.color || 'primary'" :to="{name: 'profileConnections'}"><v-icon small class="me-2">fa fa-watch-fitness</v-icon> {{ $t('events.race.course-track-device-button') }}</v-btn>
              <v-btn text :color="event.color || 'primary'" class="ms-2" :to="{name: 'activityUpload'}"><v-icon small class="me-2">fa fa-file-import</v-icon> {{ $t('events.race.course-track-import-gpx-button') }}</v-btn>
            </p>
          </v-card-text>
          <v-card-text v-if="event.allow_manual_entry">
            <h3>{{ $t('profile.activities.manualentry') }}</h3>
            <p>{{ $t('events.race.course-track-manual-msg') }}</p>
            <p>
              <v-btn text :color="event.color || 'primary'" :to="{name: 'activityEntry'}"><v-icon small class="me-2">fa fa-keyboard</v-icon> {{ $t('profile.activities.manualentry') }}</v-btn>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="accent" text @click="showTrackDialog = false">{{$t('shared.close')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>          
      <v-btn v-if="event.deeplink_url" text :color="event.color || 'primary'" @click="showQrCode=true;"><v-icon small class="me-2">fa fa-mobile</v-icon> {{ $t('events.race.course-track-app-button') }}</v-btn>
    </v-card-text>

    <v-dialog v-if="event && event.deeplink_url" v-model="showQrCode" max-width="400px">
      <v-card>
        <v-card-title>{{ $t('events.race.course-track-app-button') }}</v-card-title>
        <v-card-text>
          <v-text-field readonly v-model="event.deeplink_url"/>
          <v-btn outlined block class="shrink" @click="$helpers.copyToClipboard(event.deeplink_url, $toast);showQrCode=false;">{{ $t('shared.copy')}}</v-btn>
        </v-card-text>
        <v-card-text>{{ $t('app.scan-qr-code-msg') }}</v-card-text>
        <QrCode :data="event.deeplink_url" />
      </v-card>
    </v-dialog>      

  </div>
</template>

<script>
import { EventBus } from '@/plugins/eventbus.js';
import eventService from "@/services/eventService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import QrCode from '@/components/generic/QrCode.vue'

export default {
  name: "RaceResultMapOptions",
  components: {
    VueMarkdown,
    QrCode,
  },
  props: {
      event: Object,
      race: Object,
  },
  data() {
    return {
      status: null,
      showRouteDescriptionDialog: false,
      showRouteGpxDialog: false,
      showTrackDialog: false,
      showQrCode: false,
    };
  },
  async mounted() {
    EventBus.$on('event-status-change', async status => {
      console.log('event status changed', status);
      this.status = status;
    });

  },
  methods: {
    
    async downloadGpx() {
      try{
        const response = await eventService.exportRaceGpx(this.event.id, this.race.id);
        this.downloadGpxData(response, `${this.event.id}_${this.race.id}.gpx`);
        this.showRouteGpxDialog = false;
      }
      catch (e) {
        throw e;
        this.$helpers.toastResponse(this, { status: "ERROR", msg: "No permissions to download data."});
      }
    },
    async downloadGpxData(response, filename) {
      const blob = new Blob([response.data], { type: 'application/gpx' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename//.replace(".", "_")
        link.click()
        URL.revokeObjectURL(link.href)
    },
  },
  computed: {
    isConnected() {
      return this.status && this.status.connected;
    },
    leaderboardIsActive() {
      return this.$helpers.isActiveRace(this.event, this.race);
    },
  },
  watch: {

  },

};
</script>


<style lang="scss">

</style>

